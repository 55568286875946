.host-speech-bubble {
  background-color: #ffede7;
  border-radius: 15px;
  border-bottom-right-radius: 0px;
  padding: 1em;
  width: auto;
  max-width: 380px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  color: black;
}
