.login-v1-form-holder {
  border-radius: 8px;
  background: #ffffff;
  height: auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 2em;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}
.login-v1-form-title-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
}
.login-v1-form-title {
  color: black;
  font-weight: 600;
  font-size: 33px;
}
.login-v1-field-border {
  border-radius: 8px;
  border: 2px solid #e2e8f0;
}
.login-v1-form-field {
  width: 100%;
  padding: 0.5em;
  display: flex;
  gap: 0.5em;
  flex-direction: row;
  margin-bottom: 1em;
}
.login-v1-form-tag {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 0.7em;
  /* font-size: large; */
  font-weight: 500;
  cursor: pointer;
}
/* .login-v1-form-tag:hover {
  opacity: 0.8;
} */
.login-v1-form-tag-selected {
  background-color: #ffa382;
  color: white;
}
.login-v1-form-tag-not-selected {
  background-color: #e2e8f0;
  color: #747474;
}
.login-v1-input-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.3em;
  margin: 0 !important;
}

.login-v1-input-error {
  font-size: medium;
  padding-left: 0.5em;
  color: rgb(247, 111, 111);
  font-weight: bold;
}
.login-v1-input {
  margin: 0 !important;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 8px;
  border: 2px solid #e2e8f0;
  padding: 0.5em;
}
.login-v1-input-label {
  margin: 0 !important;
}
.login-v1-input-password-container {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  border: 2px solid #e2e8f0;
  padding: 0.5em;
  border-radius: 8px;
}
.login-v1-password-icon-holder {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: auto;
}
.login-v1-input-password {
  flex-grow: 1;
  border: none;
  outline: none;
}

.login-v1-action-button {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0.8em;
  border-radius: 8px;
  margin-top: 1.5em;
  background-color: #ffa382;
  color: white;
  font-weight: 500;
  font-size: medium;
  border-color: transparent;
  cursor: pointer;
}
.login-v1-action-button:hover {
  opacity: 0.9;
}
.login-v1-action-button:disabled {
  opacity: 0.9;
}
.login-v1-link-container {
  display: flex;
  padding: 0.8em;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.login-v1-link-text {
  color: #ffa382;
  text-decoration: underline;
  font-size: 500;
  cursor: pointer;
}
