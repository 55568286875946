.table-striped-cust tbody tr td {
  background-color: rgba(255, 255, 255, 1);
}

.table-striped-cust tbody tr:nth-of-type(odd) td:first-child {
  /* border-top-left-radius: 14px;
  border-bottom-left-radius: 14px; */
}

.table-striped-cust tbody tr:nth-of-type(odd) td:last-child {
  /* border-top-right-radius: 14px;
  border-bottom-right-radius: 14px; */
}

.table-striped-cust {
  margin-top: 25px;
}

.table-striped-cust tbody tr:nth-of-type(even) {
  background-color: rgba(255, 255, 255, 0);
}

.table-striped-cust > thead > tr > th {
  font-weight: 400;
}

.table td,
.table th {
  border-top: none !important;
}

.table td {
  vertical-align: middle;
  color: #1e293b;
  padding: 19px 14px;
}

.table td img {
  margin-right: 12px;
}

.table td.status {
  margin-right: 12px;
  color: #5d5fef;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  color: #bfbfbf;
}

.filters-bar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pagination > span {
  margin: 0px 10px;
}

.table-search {
  border-top-left-radius: 24px !important;
  border-bottom-left-radius: 24px !important;
  padding: 0.65rem 1.2rem !important;

  border-right: none;
}

.newSearchIcon {
  background: #fff;
  border-left: none !important;
  border-right: 1px solid #c6c6c6;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.table-search-icon {
  background: #fff;
  border-left: none;
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
  padding-right: 1rem;
}

.form-group {
  background: white;
  padding: 5px 15px;
  margin: 0px;
  border: 1px solid #c6c6c6;
}

.group-comb {
  margin-bottom: 1rem;
}

.group-comb > p {
  padding-left: 15px;
  font-size: 12px;
}

.form-group label {
  margin: 0px;
  font-size: 12px;
  line-height: 1;
}

.form-group select {
  padding: 0px;
  border: none;
  font-size: 15px;
}

.form-group select:focus {
  box-shadow: none;
}

.form-group input {
  padding: 0px;
  font-size: 15px;
  border: none;
  font-weight: 400 !important;
  color: black;
}

.form-group input::placeholder {
  padding: 0px;
  font-size: 15px;
  border: none;
  font-weight: 300 !important;
}

.icon-input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pin-input {
  margin-top: 5px;
  margin-bottom: 5px;
  border-right: 1px solid #7d858c;
}

.form-group input:focus {
  box-shadow: none;
}

.createdOn {
  margin-left: 10px;
  font-size: 13px;
  color: gray;
}

.createdOn > span {
  color: black;
}

.img-drop {
  height: 168px;
  background-color: #efefef;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #ffa382;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ffa382;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.solo-chain {
  font-size: 15px;
  font-weight: 500;
}

.switch-col {
  display: flex;
  align-items: center;
}

.card-cat {
  position: relative;
}

.btn-cat {
  position: absolute;
  top: -73px;
  left: 69%;
}

.validity-form {
  display: flex;
}

.sds {
  margin-left: 20px;
}

.date-start-end {
  display: flex;
  margin-top: 30px;
}

.data-time-parent {
  width: 100%;
}

.MuiButtonBase-root {
  outline: none !important;
}

/* omair table */

/* .row-cust{
  display: grid;
  grid-template-columns: 20% 10% 25% 20% 25%;
  grid-template-areas: 'orderid price date status btn';
  height: 61px;
}

.r-c-d{
  grid-template-columns: 21% 10% 26% 20% 23% !important;
} */

.row-cust {
  display: grid;
  grid-template-columns: 14% 14% 32% 18% 5%;
  grid-template-areas: "orderid price date status btn ";
  height: 61px;
}

.r-c-d {
  grid-template-columns: 14% 14% 31% 17% 45% !important;
}

div.row-cust > div {
  display: flex;
  align-items: center;

  font-size: 14px;
  line-height: 16px;
  height: 61px;
  padding: 10px 10px;
}

.cust-row-id {
  grid-area: orderid;
}

.cust-row-price {
  grid-area: price;
}

.cust-row-date {
  grid-area: date;
}

.cust-row-status {
  grid-area: status;
}

.cust-row-btn {
  grid-area: btn;
}

div.table-mod-data div.row-cust:nth-child(odd) {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(248, 248, 248, 0.25);
  border-radius: 14px;
}

.card-history {
  padding: 0rem 15px 0rem 15px !important;
}

.th {
  color: #747474;
  font-size: 14px;
  line-height: 16px;
}

.ttd {
  color: #1e293b;
}

.tds {
  color: #52f548 !important;
}

.table-mod-data {
  background-color: #ffffff;
  height: 260px;
  overflow-y: scroll;
  overflow-x: hidden !important;
}

/* custom scrollbar */
div.custom-scrollbar::-webkit-scrollbar {
  width: 20px;
  background-color: transparent;
}

div.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

div.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

div.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

div.custom-scrollbar::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

.card-new {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 8px 24px rgba(208, 208, 208, 0.25);
  border-radius: 9px;
}

.card-title {
  background: #fbfbfb;
  border-radius: 9px 9px 0px 0px;
  height: 64px;
  display: flex;
  align-items: center;
  margin: 0px !important;
}

.card-title > span {
  font-weight: 500;
  font-size: 16px;
  line-height: 139%;
  /* identical to box height, or 22px */

  color: #121212;
}

.abc-m-t {
  margin: 25px 0px;
}

.card-title-2-data {
  display: flex;
  justify-content: space-between;
}

.title-right {
  font-size: 16px !important;
  line-height: 19px !important;
  color: #999999 !important;
}

span.pricer {
  font-weight: 500 !important;
  color: grey !important;
}

.table-ac {
  background: #fff;
}

.row-cust-ac {
  display: grid;
  grid-template-columns: 15% 70% 15%;
  grid-template-areas:
    "acimg acbody acprice"
    "acimg acdesc acprice";
  height: 99px;
  border-bottom: 1px solid #ededed;
}

.cust-row-ac-img {
  grid-area: acimg;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cust-row-ac-body {
  grid-area: acbody;
  display: flex;
  align-items: flex-end;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #192746;
}

.cust-row-ac-desc {
  grid-area: acdesc;
  font-size: 12px;
  line-height: 14px;
  padding-top: 7px;
  color: #898989;
}

.cust-row-ac-price {
  grid-area: acprice;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  margin-top: -19px;
  color: #121212;
}

.ac-green {
  color: #192746;
  margin-left: 5px;
  font-size: 11px;
}

.acseller {
  margin-left: 20px;
}

.row-cust-ac:last-child {
  border-bottom: 1px solid #fff0;
}

.table-mod-data-ac {
  height: 303px;
  overflow: scroll;
  border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px;
}

/* omair profile */

.cust-user-img {
  text-align: center;
}

div.cust-user-img > img {
  width: 88px;
  height: 88px;
  border-radius: 50%;
}

.cust-user-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 139%;
  /* identical to box height, or 22px */

  text-align: center;

  color: #121212;
  margin-top: 16px;
}

.cust-user-namenew {
  font-weight: 500;
  font-size: 16px;
  line-height: 139%;
  /* identical to box height, or 22px */

  text-align: left !important;

  color: #121212;
}

.cust-user-activity {
  font-size: 12px;
  line-height: 139%;
  /* or 17px */

  text-align: center;

  color: #999999;
  margin-top: 5px;
}

.cust-user-msg {
  margin-top: 20px;
}

.cust-user-msg-parent {
  margin-top: 10px;
  border: 1px solid #e2d6d6;
  box-sizing: border-box;
  filter: drop-shadow(0px 8px 20px rgba(207, 207, 207, 0.25));
  border-radius: 9px;
  margin: 0px !important;
  height: 74px;
  display: flex;
  align-items: center;
}

.cust-user-msg-noti {
  font-size: 12px;
  line-height: 139%;
  /* or 17px */

  color: #999999;
}

.cust-user-msg-icon {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  background: linear-gradient(143.97deg, #5f61ff 5.74%, #3538f9 94.26%);
  box-shadow: 0px 2px 12px rgba(86, 86, 86, 0.25);
  border-radius: 100px;
  margin: 0px 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cust-user-member-parent {
  background: #ffe3d4;
  box-shadow: 0px 8px 20px rgba(207, 207, 207, 0.25);
  border-radius: 9px;
  margin-top: 20px;
}

.mem-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 139%;
  /* or 19px */

  color: #121212;
  display: flex;
  align-items: center;
  margin: 6px 0px;
}

.mem-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0px !important;
  margin-top: -2px;
}

.mem-header {
  font-size: 14px;
  line-height: 139%;
  /* or 19px */

  color: #999999;
  margin-bottom: 6px;
}

.cust-user-member-child {
  margin: 0px;
  padding: 15px 0px;
}

.contact-head {
  font-size: 14px;
  line-height: 139%;
  /* or 19px */

  color: #999999 !important;
  margin-top: 31px;
  margin-bottom: 17px;
}

.icon-text-div {
  display: grid;
  grid-template-columns: 15% 85%;
  grid-template-areas: "cicon ctext";
  margin-bottom: 1px;
}

.cIcon {
  grid-area: cicon;
  margin-top: -2px;
}

.cText {
  grid-area: ctext;
  font-size: 14px;
  line-height: 139%;
  /* or 19px */

  color: #060606;
}

.add-tit {
  font-size: 14px;
  line-height: 168%;
  /* identical to box height, or 24px */

  color: #121212;
}

.add-desc {
  font-size: 14px;
  line-height: 168%;
  /* or 24px */
  color: #999999;
}

.card-history-user {
  padding: 27px 19px !important;
}

#orderDetail.card-history-user {
  padding: 22px 7px !important;
}

.usr-m-b {
  margin-bottom: 25px;
  /* height: 100%; */
  padding: 30px;
}

#userDetailDiv.usr-m-b {
  margin-bottom: 25px;
  padding: 0px !important;
}

#userBilling.usr-m-b {
  padding: 20px;
}

#orderDetaills.usr-m-b {
  padding: 15px;
}

.custom-file-upload {
  position: relative;
}

.MuiStepIcon-root.Mui-completed {
  color: #ffa382 !important;
}

.MuiCompleted {
  color: #ffa382 !important;
}

.MuiStepIcon-root {
  color: rgba(236, 236, 236, 1) !important;
}
