.table-striped-cust tbody tr:nth-of-type(odd) td {
  background-color: rgba(255, 255, 255, 1);
}

.table-striped-cust tbody tr:nth-of-type(odd) td:first-child {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
}

.table-striped-cust tbody tr:nth-of-type(odd) td:last-child {
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}

.table-striped-cust tbody tr:nth-of-type(even) {
  background-color: rgba(255, 255, 255, 0);
}

.table-striped-cust>thead>tr>th {
  font-weight: 400;
}

.table-striped-cust {
  margin-top: 25px;
}

.table td,
.table th {
  border-top: none !important;
}

.table td {
  vertical-align: middle;
  color: #1f6355;
  padding: 19px 14px;
}

.table td img {
  margin-right: 12px;
}

.table td.status {
  margin-right: 12px;
  color: #5d5fef;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  color: #bfbfbf;
}

.filters-bar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pagination>span {
  margin: 0px 10px;
}

.table-search {
  border-top-left-radius: 24px !important;
  border-bottom-left-radius: 24px !important;
  padding: 0.65rem 1.2rem !important;

  border-right: none;
}

.table-search-icon {
  background: #fff;
  border-left: none;
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
  padding-right: 1rem;
}

.solo-chain-2 {
  margin-bottom: 10px;
}

.solo-chain-2>span {
  font-size: 17px;
  font-weight: 500;
}

.counter-text {
  text-align: right;
}

.t-a {
  border: none;
}

.add-catalog>.row>div>div.group-comb>div.form-group {
  border: 1px solid #c6c6c6;
}

.card-pro {
  position: relative;
}

.btn-pro {
  position: absolute;
  top: -73px;
  left: 45%;
}

.sect-cat {
  font-size: 12px;
  color: #1f6355;
}

.sect-num {
  font-size: 18px;
  line-height: 21.09px;
  color: #1f6355;
}

.prod-p {
  font-size: 16px;
  color: #686868;
}

.bg-secondary {
  background-color: #f4f4f4 !important;
}

.top-head {
  color: #1f6355;
  font-size: 26px;
  font-weight: 500;
}

.size-text {
  font-size: 10px !important;
}

.mdl {
  max-width: 800px !important;
  position: relative;
}

@media (min-width: 576px) {
  .mdl {
    margin: 1.75rem auto;
  }
}

.modal-content {
  border-radius: 1rem;
}

.step-circle {
  height: 42px;
  width: 42px;
  border: 6px solid #5d5fef;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  font-size: 18px;
}

.step-circle-active {
  background-color: #5d5fef;
  color: #fff;
}

.step-1 {
  border-bottom: 3px solid #e8e8ff;
  padding: 0px;
}

.step-1>div {
  padding-bottom: 15px;
}

.step-active {
  border-bottom: 3px solid #5d5fef;
  padding: 0px;
}

.dropdown-search {
  width: 94%;
  margin-top: 0px;
}

.labelStyle {
  color: #747474;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  display: block;
  margin-left: 5px;
}

.table-search:focus {
  box-shadow: none;
}

.dropdown-img {
  height: 50px !important;
  width: 50px !important;
  margin-right: 15px;
}

.percent {
  color: #1f6355;
  font-size: 18px;
  position: relative;
  padding-bottom: 25px;
}

.percent>span {
  margin-left: 40px;
}

.percent>i.fa-sort-down {
  position: absolute;
  font-size: 44px;
  top: -18px;
}

.percent>i.fa-sort-up {
  position: absolute;
  font-size: 44px;
  top: 2px;
}

.second-row {
  font-size: 12px;
  color: #898989;
  padding-bottom: 10px;
}

.second-row>span {
  font-size: 12px;
  color: #1f6355;
}

.sel-product {
  margin: 0px;
  margin-top: 15px;
}

.modal-body {
  padding: 1rem 3rem;
}

.modal-header {
  padding: 1rem 3rem;
  align-items: center;
}

.modal-header>i {
  color: #bcbcbc;
}

.MuiFormControl-root {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.css-1iz24ed-MuiChip-root {
  background: #5d5fef !important;
  color: #fff !important;
  border-radius: 4px !important;
  height: 24px !important;
}

.MuiSelect-select {
  border: 0px solid black !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiSelect-select {
  min-height: 32px !important;
  padding: 0px !important;
}

.form-check input[type="checkbox"]+label::after {
  position: absolute;
  z-index: 1;
  content: "";
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border: solid 1px #000;
  border-radius: 0;
}

.form-check input[type="checkbox"]:checked+label::after {
  background-color: #ff1562;
  border: solid 1px #ff1562;
}

.form-check input[type="checkbox"]:checked+label::before {
  font-family: "Font Awesome 5 Free";
  content: "\f00c";
  display: inline-block;
  top: 5px;
  left: 2px;
  width: 15px;
  height: 14px;
  text-align: center;
  font-size: 10px;
  color: var(--main-color);
  position: absolute;
  z-index: 2;
  padding: 0;
  line-height: 14px;
  font-weight: 900;
  color: #fff;
}

.form-check>input {
  display: none;
}

.form-check-label {
  margin-left: 10px;
}

.switch-demo {
  display: flex !important;
  align-items: center;
}

.btn-cannaby,
.btn-cannaby:hover {
  background: #5d5fef;
  color: #fff;
  margin-top: 30px;
}

.spec-hei {
  height: 84px !important;
}

.slider-text {
  color: #5d5fef;
}

h5 {
  margin-top: 5px;
}

.image-thumbnail-div {
  position: relative;
  margin-right: 12px;
}

.image-thumbnail-div span {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: capitalize;
}