.neuro {
  background: #f6f4f4;
  box-shadow: 11px 11px 22px #f1efef, -11px -11px 22px #fbf9f9;
}

.session-focus-progress {
  animation: session-focus-animation 2s;
  animation-iteration-count: infinite;
}
.eachsessioncard {
  transition: all 0.3s ease-in-out;
}
.eachsessioncard:hover {
  /* transform: scale(1.02); */
  transform: none;
}

@keyframes session-focus-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.990099773451273;
  }
  101% {
    opacity: 0.99;
  }
}

@-moz-keyframes session-focus-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.990099773451273;
  }
  101% {
    opacity: 0.99;
  }
}
