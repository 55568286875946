.registrationPop {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 14px;
  top: 0.5px;
}
.LoyaltyPop {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: 8em;
  top: 0.5px;
}
.LoyaltySpecialPop {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: 12em;
  top: 0.5px;
}
.registrationToggle {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: -92px;
  top: 3.5px;
}
