.client-speech-bubble {
  background-color: #edf2fc;
  border-radius: 15px;
  border-bottom-left-radius: 0px;
  padding: 1em;
  width: auto;
  color: black;
  max-width: 380px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
