input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: none;
  display: block;
  margin: auto;
  padding: 6px 12px;
  cursor: pointer;
  text-align: center;
  font-size: 15px;
}

.custom-file-upload:hover {
  color: #5d5fef;
  font-weight: 900;
}

.table-striped-cust tbody tr:nth-of-type(odd) td {
  background-color: rgba(255, 255, 255, 1);
}

.table-striped-cust tbody tr:nth-of-type(odd) td:first-child {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
}
.table-striped-cust tbody tr:nth-of-type(odd) td:last-child {
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}

.table-striped-cust {
  margin-top: 25px;
}

.table-striped-cust > thead > tr > th {
  font-weight: 400;
}

.table-striped-cust tbody tr:nth-of-type(even) {
  background-color: rgba(255, 255, 255, 0);
}

.table td,
.table th {
  border-top: none !important;
}

.table td {
  vertical-align: middle;
  color: #1e293b;
  padding: 19px 14px;
}

.table td img {
  margin-right: 12px;
}

.table td.status {
  margin-right: 12px;
  color: #5d5fef;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  color: #bfbfbf;
}

.filters-bar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pagination > span {
  margin: 0px 10px;
}

.table-search {
  border-top-left-radius: 24px !important;
  border-bottom-left-radius: 24px !important;
  padding: 0.65rem 1.2rem !important;

  border-right: none;
}

.table-search-icon {
  background: #fff;
  border-left: none;
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
  padding-right: 1rem;
}

.App {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 310px;
}

.crop-container {
  position: relative;
  top: 0;
  left: 50%;
  width: 50%;
  height: 180px;
  transform: translateX(-50%);

  right: 0;
  bottom: 0;
}

.controls {
  position: relative;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 0 px 0px;
}

.crop-btn {
  position: relative;
  bottom: 0;
  left: 50%;
  width: 20%;
  transform: translateX(-50%);
  height: 20px;
  display: flex;
  align-items: center;
}

.galimage[src=""] {
  display: none !important;
}

.form-control:disabled {
  background-color: transparent;
}
