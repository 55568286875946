.BannerMain {
  height: 100vh;
  padding: 10px;
}

.BannerFileuploader {
  width: 80%;
  background-color: rgba(226, 232, 240, 0.5);
  margin-right: auto;
  margin-left: auto;
  border-radius: 5px;
  height: 40vh;
  margin-top: 10px;
}
.BannerFileuploader2 {
  background-color: #fafbff;
  margin-right: auto;
  margin-left: auto;
  border-radius: 5px;
  height: 40vh;
  width: 80%;
}

.myInput {
  display: block;
  width: 100%;
  border: 1.2px solid rgba(226, 232, 240, 1);
  border-radius: 5px;
  padding: 0.375em 0.375em;
}

.createButton {
  background-color: #ffa382;
  color: white;
  border: none;
  height: 40px;
  width: 15%;
  margin-left: 10px;
}

.cancelButton {
  background-color: #e2e8f0;
  color: #747474;
  border: none;
  height: 40px;
  width: 15%;
}

.defaultImage {
  display: flex;
  justify-content: center;
  width: 20%;
  margin: auto auto;
  position: relative;
}

.bannerAdd {
  background-color: white;
  color: #ffa382;
}
