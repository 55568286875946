:root {
  --color-primary-main: #ffa382;
  --color-secondary-main: linear-gradient(#061049, #192746);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.popup-overlay {
  background-color: rgba(0, 0, 0, 0.3);
}
.popup-content {
  background-color: white;
  border-radius: 20px;
  width: 30vw;
}

.emsg {
  color: red;
  opacity: 0.85;
  font-size: 11.5px;
  margin-left: 9px;
  margin-top: -5px;
}

.form-control::-webkit-input-placeholder {
  color: gray;
}

input::placeholder {
  color: gray;
}

/** Utility class */
.flex-gap-1 {
  gap: 15px;
}

.css-j204z7-MuiFormControlLabel-root {
  cursor: initial !important;
}
.disabled-button{
  background: #061049 !important;
}