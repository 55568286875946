.table-striped-cust tbody tr:nth-of-type(odd) td {
  background-color: rgba(255, 255, 255, 1);
}

.table-striped-cust tbody tr:nth-of-type(odd) td:first-child {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
}

.table-striped-cust tbody tr:nth-of-type(odd) td:last-child {
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}

.table-striped-cust {
  margin-top: 25px
}

.table-striped-cust tbody tr:nth-of-type(even) {
  background-color: rgba(255, 255, 255, 0);
}

.table-striped-cust>thead>tr>th {
  font-weight: 400;
}


.table td,
.table th {
  border-top: none !important;
}

.table td {
  vertical-align: middle;
  color: #1F6355;
  padding: 19px 14px;
}

.table td img {
  margin-right: 12px;
}

.table td.status {
  margin-right: 12px;
  color: #5D5FEF;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  color: #BFBFBF;
}

.filters-bar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pagination>span {
  margin: 0px 10px
}

.table-search {
  border-top-left-radius: 24px !important;
  border-bottom-left-radius: 24px !important;
  padding: 0.65rem 1.2rem !important;

  border-right: none;
}

.table-search-icon {
  background: #fff;
  border-left: none;
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
  padding-right: 1rem;
}

.adminName {
  font-size: 13px;
  color: #1E293B;

}

.adminName>span {
  font-size: 11px;
  color: #1E293B;
}

/* .MuiPaginationItem-page {
  border-radius: 5px !important;
  background-color: #FFA382 !important;
} */

.MuiPaginationItem-previousNext {
  border-radius: 5px !important;
  background-color: #F8FAFC !important;
}

.MuiPaginationItem-root {
  border-radius: 5px !important;
  background-color: #F8FAFC !important;
}

/* .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #FFA382 !important; .css-1xr9krm
} */

.Mui-selected {
  background-color: #FFA382 !important;
}