.card-cat {
  position: relative;
}
.temp-chart {
  height: 550px !important;
}
.card-dashboard {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 8px 24px rgba(208, 208, 208, 0.25);
  border-radius: 20px;
  padding: 18px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas:
    "icon"
    "text"
    "stat";
}

.dashImage {
  height: 20vh;
  margin: auto;
}

.dashDiv {
  margin: auto;
  height: 20vh;
  text-align: center;
  align-items: center;
}

.dashHeading {
  color: #121212;
  margin: auto;
  padding-top: 3vh;
}

.dashText {
  color: #747474;
  margin: auto;
}

.new-Iconbox {
  height: 8vh;
  align-self: center;
  background-color: #ffa382;
  width: 25%;
  border-radius: 8px;
}

.new-Iconbox > img {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 50%;
  height: 100%;
}

.padding-0 {
  padding-right: 8px;
  padding-left: 8px;
}

.chart-option {
  font-size: 14px;
  color: #797979;
}

.chart-select {
  border: none;
  /* width:100px !important; */
  padding-right: 6px;
}

.card-chart {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 8px 24px rgba(208, 208, 208, 0.25);
  border-radius: 20px;
  padding: 20px 20px 20px 20px;
}
.value-container > .value {
  margin-bottom: 0px;
  text-align: right;
  color: black;
}
.value-container .value-label {
  text-align: right;
  color: #999999;
  font-size: 10px;
}
.image-avatar {
  height: 50px;
  width: 50px;
}

.robo {
  width: 205px;
  height: 205px;
}

.card-dash-icon {
  grid-area: icon;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.card-dash-icon > span {
  background: #ffffff;
  border-radius: 89px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-dash-icon > span > svg {
  width: 48px;
  height: 48px;
  color: #1f6355;
}

.btn-icon > span {
  margin-left: 6px;
}

.card-dash-text {
  grid-area: text;
  color: #192746;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 46.52px;
  /* margin: auto; */
  text-align: left;
  /* or 47px */
}

.dashboard-total-count {
  color: #ffa382;
}

.card-text {
  text-align: center;
  /* text-transform: uppercase; */
  color: gray;
  font-family: Roboto;
  font-size: 14px;
}

.card-dash-text > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 23.12px;
  text-transform: uppercase;
}

.card-dash-btn {
  grid-area: btn;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.card-dash-stat {
  grid-area: stat;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 129.22%;
  background: #ffffff;
  border-radius: 12px;
  padding: 17px 14px;
  margin-top: 16px;
}

.card-dash-stat > div {
  display: flex;
  justify-content: space-between;
}

.stat-disabled {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 129.22%;

  margin: 14px 0px;

  color: #ef5da8;
}

.stat-enabled {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 129.22%;
  /* identical to box height, or 18px */

  color: #1f6355;
}
