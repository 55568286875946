.loyalty input {
  width: 100px;
}

.select-time label {
  padding-top: 10px;
  padding-right: 5px;
  padding-left: 5px;
}

.addPoints {
  background-color: #ffa382 !important;
  margin-right: -6px !important;
}
.showQR {
  background-color: #ffa382 !important;
  margin-right: -6px !important;
}
