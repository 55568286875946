.cannaby-logo {
  margin-bottom: 38px;
}

.sidebar {
  background: linear-gradient(#061049, #192746) !important;
}

ul.cannaby-nav>li.nav-item>a.nav-link {
  color: #ffffff;
  text-align: left;
  display: flex;
  align-items: baseline;
  padding: 1rem 1rem !important;
}

ul.cannaby-nav>li.nav-item>a.nav-link>i {
  margin-right: 9px;
}

ul.cannaby-nav>li.nav-item>a.nav-link>span {
  display: block;
}

ul.cannaby-nav>li.nav-item>a.active,
.drop-menu a.active {
  background-color: #e6916660 !important;
  /* color: white !important; */
  padding: 1rem 1rem !important;
  border-radius: 13px !important;
}

.drop-menu a {
  margin-left: 15px;
  /* margin-right: -30px; */
}

.nav-item img {
  width: 20px;
}

.drop-menu button,
.drop-menu .nav-link {
  border: none;
  background-color: transparent;
  /* background-color: white !important; */
  color: #ffffff !important;
  padding: 1rem 1rem !important;
  border-radius: 13px !important;
}

.drop-menu .nav-link a {
  padding-left: 0;
  padding-right: 0;
}

.dropdown a.active {
  background-color: white !important;
  color: #1f6355 !important;
  padding: 1rem 1rem !important;
  border-radius: 13px !important;
}

.drop-menu i {
  margin-right: 9px;
}

.scroll {
  overflow-y: auto;
}

.logout-button {
  padding-bottom: 30px;
}

.text-danger:hover {
  color: #fff !important;
}

.drop-menu .btn:focus {
  box-shadow: none;
}

.side-nav .table-mod-data-ac {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.card-side-nave>div {
  padding: 2rem 1.5rem 2rem 2rem;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 18px !important;
}