.reset-password-group {
  display: flex;
  flex-direction: column;
  width: 40vw;
  margin-top: 2em;
}

.reset-password-field {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  background-color: #E2E8F0;
  padding: 1.29px;
  border: 1px soild #E2E8F0;
  border-radius: 10px;
  overflow: hidden;
}

.reset-password-icon {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.reset-password-input {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 0.8em;
  border-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}