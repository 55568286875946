.disableDeal {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #ffe5e7;
  border-radius: 20px;
  border: none;
  height: 35px;
  width: 75px;
  color: #dc3545;
}
.enableDeal {
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(12, 214, 0, 0.08);
  border-radius: 20px;
  border: none;
  height: 35px;
  width: 75px;
  color: #0cd600;
}
