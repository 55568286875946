.login-page-v1-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100vw !important;
  height: 100vh !important;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  padding: 0 !important;
  margin: 0 !important;
  justify-content: flex-start;
}

.login-page-v1-logo-container {
  display: none;
  justify-content: flex-end;
  width: 55vw;
  height: 100vh;
  background: linear-gradient(
    344deg,
    rgba(34, 52, 66, 1) 6%,
    rgba(18, 28, 35, 1) 54%
  );

  position: relative;
}
.login-page-v1-shadow-ball2 {
  position: absolute;
  position: absolute;
  width: 23vw;
  height: 23vw;
  border-radius: 50%;
  top: 4vw;
  left: -8vw;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 17.71%,
    rgba(255, 255, 255, 0) 100%
  );
}

.login-page-v1-shadow-ball1 {
  position: absolute;
  width: 36vw;
  height: 36vw;
  border-radius: 50%;
  top: -8vw;
  left: -4vw;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.16) 17.71%,
    rgba(255, 255, 255, 0) 100%
  );
}

.login-page-v1-shadow-ball3 {
  position: absolute;
  width: 7vw;
  height: 7vw;
  border-radius: 50%;
  bottom: 15vh;
  left: 42vw;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.16) 17.71%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(-90deg);
}

.login-page-v1-logo-image-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: transparent;
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  padding: 45px;
}
.login-page-v1-form-holder {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  flex-grow: 1;
  background-color: #eff3fc;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding: 3em;
}

@media screen and (min-width: 1040px) {
  .login-page-v1-logo-container {
    display: flex;
  }
}
