.mdl {
    
    max-width: 800px !important;
    position: relative;
}

.my-btn {
    width: 100%;
    background-color: transparent;
    color: black;
    border: none;
}

@media (min-width: 576px) {
.mdl {
    margin: 1.75rem auto;
    }
}