span.notification-icons>span {
  margin: 0px 9px;
  font-size: 21px;

}

.notification-icons {
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  margin-left: 25px;
  padding-left: 39px;

}


.user-head {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.navbar-brand {
  display: flex;
  align-items: center;
}


.row-header {
  width: 100%;

}

.validation-help {
  color: red !important;
}

.col-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.goback-nav {
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  border-radius: 50%;
  font-size: 1.4rem;
  background-color: white;
  color: #1F6355;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}

.navbar-brand {
  color: #1F6355;
}

.navbar-brand>h2 {
  margin: 0px;
}

.navbar {
  padding: 0rem 0px 1rem !important;
  margin-top: -15px;
}

/* card */
.card {
  background-color: #fff;
  border: none !important;
  border-radius: 27px;
  background-color: #d9d9d9;
  background-image: linear-gradient(315deg, #d9d9d9 0%, #f6f2f2 81%);

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}


.card>div {
  margin: 0;
  padding: 2rem;
  height: 100%;
  background: #fff;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

#nopad>div {
  margin: 0;
  padding: 0em;
  height: 100%;
  background: #fff;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

#nopad1>div {
  margin: 0;
  padding: 1em;
  height: 100%;
  background: #fff;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

#editButton.btn-cannaby {
  background-color: #5d5fef !important;
}

.card-side-nav>div {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.top-container {
  display: flex;
  align-items: center
}

.profile-image {
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.name {
  font-size: 15px;
  font-weight: bold;
  color: #272727;
  position: relative;
  top: 8px
}

.mail {
  font-size: 14px;
  color: grey;
  position: relative;
  top: 2px
}

.middle-container {
  background-color: #eee;
  border-radius: 12px
}

.middle-container:hover {
  border: 1px solid #5957f9
}

.dollar-div {
  background-color: #5957f9;
  padding: 12px;
  border-radius: 10px
}

.round-div {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center
}

.dollar {
  font-size: 16px !important;
  color: #5957f9 !important;
  font-weight: bold !important
}

.current-balance {
  font-size: 15px;
  color: #272727;
  font-weight: bold
}

.amount {
  color: #5957f9;
  font-size: 16px;
  font-weight: bold
}

.dollar-sign {
  font-size: 16px;
  color: #272727;
  font-weight: bold
}

.recent-border {
  border-left: 2px solid #5957f9;
  display: flex;
  align-items: center
}

.recent-border:hover {
  border-bottom: 1px solid #dee2e6 !important
}

.recent-orders {
  font-size: 16px;
  font-weight: 700;
  color: #5957f9;
  margin-left: 2px
}

.wishlist {
  font-size: 16px;
  font-weight: 700;
  color: #272727
}

.wishlist-border:hover {
  border-bottom: 1px solid #dee2e6 !important
}

.fashion-studio {
  font-size: 16px;
  font-weight: 700;
  color: #272727
}

.fashion-studio-border:hover {
  border-bottom: 1px solid #dee2e6 !important
}

.profile-section {
  position: fixed;
  width: 310px;
  height: 100vh;
  top: 0px;
  right: 0px;
  z-index: 5;
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(130, 130, 130, 0.25);
}


.label-close {
  position: absolute;
  cursor: pointer;
  margin: 15px;
  background: #FFFFFF;
  box-shadow: 0px 8px 20px rgb(207 207 207 / 25%);
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;

}

.profile-parent {
  position: relative;
  padding: 58px 23px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.nav-msg {
  background: #E7FFE6;
  box-shadow: 0px 4px 24px rgba(248, 248, 248, 0.25);
  border-radius: 14px;
  display: flex;
  align-items: center;
}

.nav-msg>p {
  margin: 0px;
  padding: 17px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;

  color: #1F6355;
}


.top-container-rnav {
  display: flex;
  flex-direction: column;
}

.rnav-name>label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  margin: 0px;
  color: #8F8F8F;
}


.rnav-name>input {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding: 5px 0 !important;
  /* identical to box height */
  border: none;

  color: #000;
}

select.form-control:not([size]):not([multiple]) {
  height: auto !important;
}


.pin-input-rnav {
  padding-right: 0px;
}

.phone-input-rnav {
  padding-left: 0px;
}

.phone-input-rnav>input {
  border-radius: 0px;
  border: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}


.pin-input-rnav>select {
  border-radius: 0px;
  border: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  height: 30px !important;
}

.phone-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  margin: 0px;
  color: #8F8F8F;
}

.rnav-name {
  margin-top: 23px;
  padding: 0px 10px;
  border: 1px solid #c6c6c600;
}


.rnav-phone {
  margin-top: 13px;
  padding: 0px 10px;

  border: 1px solid #c6c6c600;
}

.rnav-input-active {
  border: 1px solid #C6C6C6;

  border-radius: 14px;
}


.rnav-input-active>input:focus {
  box-shadow: none;
}

.pin-input-rnav>select:focus {
  box-shadow: none;
}

.phone-input-rnav>input:focus {
  box-shadow: none;
}



.rnav-image {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
}

.image-container-rnav {

  width: 129px;
  height: 129px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
}


.image-container-rnav>img {

  width: 129px;
  height: 129px;
  border-radius: 50%;
  border: 1px solid black;
}


.custom-file-upload-rnav {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  border-radius: 50%;
  background: #FFFFFF;
  box-shadow: 0px 8px 20px rgb(207 207 207 / 25%);
  font-size: 10px;
  color: #1F6355;

  cursor: pointer;
}


.btn-rnav {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(130, 130, 130, 0.25);
  border: 1px solid black;
  color: #000;
  margin: 0px 5px
}

.fa-pen {
  color: #5957f9;
  cursor: pointer;
}


.btn-primary {
  background-color: #5957f9;
}

.btn-rnav:hover {
  background: #5957f9;
  border: 1px solid #5957f9;
  color: white;
}



.btn-rnav:focus {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(130, 130, 130, 0.25);
  border: 1px solid black;

  color: #000;
}


.rnav-btn-grp {
  margin-top: 29px
}

.parent-right-nav {
  padding: 0px;
}

.App {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.crop-container {
  position: relative;
  top: 0;
  left: 50%;
  width: 50%;
  height: 180px;
  transform: translateX(-50%);

  right: 0;
  bottom: 0;
}

.controls {
  position: relative;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 0 px 0px;
}

.crop-btn {
  text-align: center !important;
  height: 30px;
  padding: 0;
}

/* .group-comb .form-control{
height: calc(1.25rem + 2px) !important;
} */

.group-comb label {
  color: #8F8F8F
}

.invert {
  padding: 3px 4px;
  background-color: #fff;
  color: #5957f9;
  border-radius: 20px;
}



.galimage {
  width: calc(100% + 35px);
  height: calc(100% + 15px);
  object-fit: cover;
  margin-top: -33px;
  border-radius: 15px !important;
  margin-left: -17px !important;
}

.dropdown .show {
  border-radius: 20px;
}

.nav-item {
  padding: 2px;
}

.table-striped-cust th {
  color: #747474 !important;
}