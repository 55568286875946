.popup-heading {
  font-weight: 300 !important;
  color: #192746;
  font-size: 15px;
}

.fa-exclamation-triangle {
  font-size: 60px;
  margin-bottom: 40px;
  color: red;
}

.myPopup {
  padding: 30px 20px !important;
  height: 35vh;
}

.popup-cancel-button {
  background-color: red !important;
  color: white;
}

.popup-content {
  padding: 30px 20px !important;
  height: 35vh;
}

.response-popup .popup-content {
  width: 450px;
}
