.table-striped-cust tbody tr td {
  background-color: rgba(255, 255, 255, 1);
}

.table-striped-cust tbody tr td {
  /* border-top-left-radius: 14px;
  border-bottom-left-radius: 14px; */
}

.table-striped-cust tbody tr td:last-child {
  /* border-top-right-radius: 14px;
  border-bottom-right-radius: 14px; */
}

.chatDiv {
  display: flex;
  border: 1px solid rgba(116, 116, 116, 0.2);
  height: 10vh;
  /* justify-content: space-around; */
  align-items: center;
  border-radius: 6px;
  margin-top: 9px;
}

.newMsg {
  font-size: 11px;
  color: rgba(153, 153, 153, 1);
  text-align: left;
  margin-left: 25px;
  /* margin-top: 14px; */
  /* padding-left: 6px; */
}

.table-striped-cust {
  margin-top: 25px;
}

.table-striped-cust tbody tr:nth-of-type(even) {
  background-color: rgba(255, 255, 255, 0);
}

.table-striped-cust > thead > tr > th {
  font-weight: 400;
  background-color: #f8fafc;
}

.table td,
.table th {
  border-top: none !important;
}

.table td {
  vertical-align: middle;
  color: #1f6355;
  padding: 19px 14px;
}

.table td img {
  margin-right: 12px;
}

.table-mod-data {
  height: 260px;
  overflow: scroll;
}

.table td.status {
  margin-right: 12px;
  color: #5d5fef;
}

.table td.inactive {
  margin-right: 12px;
  color: red;
}

.image-thumbnail-div {
  position: relative;
  margin-right: 12px;
}

.image-thumbnail-div span {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: capitalize;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  color: #f8fafc;
}

.filters-bar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pagination > span {
  margin: 0px 10px;
}

.table-search {
  border-top-left-radius: 24px !important;
  border-bottom-left-radius: 24px !important;
  padding: 0.65rem 1.2rem !important;

  border-right: none;
}

.table-search-icon {
  background: #fff;
  border-left: none;
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
  padding-right: 1rem;
}

.adminName {
  font-size: 13px;
}

.adminName > span {
  font-size: 11px;
}

.newClass {
  width: 140%;
  height: 25px;
  /* background-color: rgba(12, 214, 0, 0.08); */
  border-radius: 13px;
  z-index: 100;
  position: relative;
}

.newClassUsers {
  width: 140%;
  height: 25px;
  background-color: rgba(12, 214, 0, 0.08);
  border-radius: 13px;
  z-index: 100;
  position: relative;
}

#coupons.newClass {
  width: 100% !important;
  height: 25px;
  /* background-color: rgb(251, 255, 194); */
  border-radius: 13px;
  z-index: 100;
  position: relative;
}

#deals.newClass {
  width: 100%;
  height: 25px;
  border-radius: 13px;
  z-index: 100;
  position: relative;
}

#disablebutton {
  background-color: rgba(255, 109, 109, 0.1);
  text-align: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 13px;
}

#enablebutton {
  background-color: rgba(12, 214, 0, 0.1);
  text-align: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 13px;
}

.medicalLicenceApproved {
  width: 40%;
  height: 25px;
  background-color: rgba(12, 214, 0, 0.1);
  border-radius: 13px;
  z-index: 100;
  position: relative;
}

/* pagination STYLING */
.pageNumberArea {
  align-self: center;
  width: 12%;
  justify-content: space-between;
}

.paginationButton {
  background-color: #ffa382;
  width: 30%;
  height: 5vh;
  border-radius: 5px;
  border: none;
}

.billingUsers {
  justify-content: space-between;
  margin-top: 20px;
}

.productImage {
  width: 100%;
  padding-right: 25px;
  height: 90px;
}

.newViewDetail {
  border: none;
  background-color: white;
  color: #5d5fef;
  color: #ffa382;
  width: 50%;
}
