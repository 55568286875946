/* body {
  background: #edc0bf;
  background-image: radial-gradient(circle,
      #edc0bf,
      #edbfc9,
      #e9c0d4,
      #e1c2de,
      #d6c5e7,
      #d6c5e7,
      #d6c5e7,
      #d6c5e7,
      #e1c2de,
      #e9c0d4,
      #edbfc9,
      #edc0bf);
} */

.right-column {


  border-radius: 20px;
  padding: 30px;
  margin-bottom: 20px;

}

html,
body {
  height: 100%;
  font-family: "Roboto", sans-serif;
  margin-top: 0px !important;
}

body > iframe {
  display: none;
}

h1,
h2,
h3,
h4,
h6 {

  color: #E69066;

}

h5 {
  color: black;
}

h2 {
  font-size: 28px;
}

p,
a,
td,
span,
button {
  font-size: 14px;
}

.backdrop {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0px;
  background: #EFF3FC;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

#root {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.row-main {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding-right: 3px;
}

.side-nav {
  position: relative;
}

.card-side-nav {
  background-color: #bdd4e7;
  background-image: linear-gradient(315deg, #fdfdfd 0%, #fbf8f8 74%);
  margin: 10px;
  height: 100%;
  width: 195px;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  /* border-radius: 17px;
    padding: 10px; */
}

.bg-none {
  background: none;
  border: none;
}

button>i {
  margin-right: 9px;
}

.cannaby-light {
  color: black;
  background-color: white !important;
  margin: 0px 5px;
}

.cannaby-light:hover,
.cannaby-light:active {
  background-color: #5c5eec !important;
  color: white;
}

.cannaby-light:focus {
  background-color: #5c5eec !important;
  color: white;
  box-shadow: none !important;
}

.orangeColor {
  color: #FFA382
}

.alertBox {
  background-color: #192746;
  color: #FFA382;
}

select.decorated option:hover {
  background-color: #1882A8 !important;
  }
 
  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
@keyframes fadeInFromLeft {
  from {
    transform: translateX(-20px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes fadeInFromRight {
  from {
    transform: translateX(20px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes fadeInFromBottom {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes fadeInFromTop {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

