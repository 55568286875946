input[type="file"] {
  display: none;
}


.image-thumbnail-div {
  border-radius: 20%;
}

.custom-file-upload {
  border: 1px solid gray;
  display: block;
  margin: auto;
  padding: 6px 12px;
  cursor: pointer;
}

.table-striped-cust tbody tr:nth-of-type(odd) td {
  background-color: rgba(255, 255, 255, 1);
}

.table-striped-cust tbody tr:nth-of-type(odd) td:first-child {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
}

.table-striped-cust tbody tr:nth-of-type(odd) td:last-child {
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}

.table-striped-cust {
  margin-top: 25px
}

.table-striped-cust tbody tr:nth-of-type(even) {
  background-color: rgba(255, 255, 255, 0);
}

.table-striped-cust>thead>tr>th {
  font-weight: 400;
}


.table td,
.table th {
  border-top: none !important;
}

.table td {
  vertical-align: middle;
  color: #1F6355;
  padding: 19px 14px;
}

.table td img {
  margin-right: 12px;
}

.table td.status {
  margin-right: 12px;
  color: #5D5FEF;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  color: #BFBFBF;
}

.filters-bar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pagination>span {
  margin: 0px 10px
}

.table-search {
  border-top-left-radius: 24px !important;
  border-bottom-left-radius: 24px !important;
  padding: 0.65rem 1.2rem !important;

  border-right: none;
}

.table-search-icon {
  background: #fff;
  border-left: none;
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
  padding-right: 1rem;
}

.form-group {
  background: white;
  padding: 5px 15px;
  border-radius: 3px;
  margin: 0px;
  border: 1px solid rgba(226, 232, 240, 1);
}

.form-control {
  color: black !important;
  font-weight: 400;
}

.form-control::placeholder {
  color: black !important;
  font-weight: 300;
}

.group-comb {
  margin-bottom: 1rem;
}

.group-comb>p {
  padding-left: 15px;
  font-size: 12px;
}

.form-group label {
  margin: 0px;
  font-size: 12px;
  line-height: 1;
}

.form-group select {
  padding: 0px;
  border: none;
  font-size: 15px;
}

.form-group select:focus {
  box-shadow: none;
}

.form-group input {
  padding: 0px;
  font-size: 15px;
  border: none
}

.icon-input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pin-input {
  margin-top: 5px;
  margin-bottom: 5px;
  border-right: 1px solid #7d858c;
}

.form-group input:focus {

  box-shadow: none
}

.createdOn {
  margin-left: 10px;
  font-size: 13px;
  color: gray;
}

.createdOn>span {
  color: black;
}

.img-drop {
  height: 168px;
  background-color: #efefef;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.solo-chain {
  font-size: 15px;
  font-weight: 500;
}

.switch-col {
  display: flex;
  align-items: center;
}

.card-cat {
  position: relative;
}

.btn-cat {
  position: absolute;
  top: -73px;
  left: 69%;
}

.headerEA {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-btn {
  background: none !important;
}

.close-btn:focus {
  background: none !important;
  box-shadow: none !important;
}

.App {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.crop-container {
  position: relative;
  top: 0;
  left: 50%;
  width: 50%;
  height: 180px;
  transform: translateX(-50%);

  right: 0;
  bottom: 0;
}

.controls {
  position: relative;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 0px 0px;
}

.crop-btn {
  position: relative;
  bottom: 0;
  left: 50%;
  width: 20%;
  transform: translateX(-50%);
  height: 20px;
  display: flex;
  align-items: center;
}

#editSection.card>div {
  padding: 1rem !important;
}

.editHeading {
  color: #FFA382;
  margin-left: 10px;
  margin-top: 7px;
  font-size: 15px
}

.latestCross {
  display: flex;
  align-items: flex-start;
  /* background-color: #FF6D6D !important; */
  border-radius: 30px;
  width: 33px;
  height: 30px;
  margin-top: -3px;
  margin-right: -4px;
}